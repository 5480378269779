const initialState = {
    data: [],
    total: 1,
    params: {},
}

const advocateReducer = (state = initialState, action) => {
    switch (action.type) {

        case 'GET_DATA_ADVO_LIST':
            return {
                ...state,
                allData: action.allData,
                data: action.data,
                total: action.totalPages,
                params: action.params
            }

        case 'CREATE_ADV_FORM':
            return { ...state }

        case 'UPDATE_ADV_FORM':
            return { ...state }

        default:
            return { ...state }
    }
}
export default advocateReducer
