const initialState = {
    data: [],
    total: 1,
    params: {},
    detailList: {},
    landTypeList: {},
    activityLogList: []

}

const paidRehabilitationReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_PAID_REHABILITATION_DATA':
            return {
                ...state,
                allData: action.allData,
                data: action.data,
                total: action.totalPages,
                params: action.params
            };
        case 'UPDATE_NEW_PAID_REHABILITATION':
            return {
                ...state,
            }

        case 'CREATE_NEW_PAID_REHABILITATION':
            return {
                ...state,
            }
        default:
            return { ...state }
    }
}
export default paidRehabilitationReducer
