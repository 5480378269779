/**
 * Router service file
 */

// Async component
import { HulkPageLoader } from "components/GlobalComponents";
import Loadable from "react-loadable";

import {
  AsyncAcceptanceDashboardComponent,
  AsyncFormInformationComponent,
  AsyncUserMasterComponent,
  AsyncWelcomeComponent,
  AsyncSettingComponent,
  AsyncDesignationComponent,
  AsyncDepartmentComponent,
  AsyncAreaComponent,
  AsyncWardComponent,
  AsyncSchemeTypeComponent,
  AsyncArchitectComponent,
  AsyncDeveloperComponent,
  AsyncLandTypeComponent,
  AsyncAnnexureComponent,
  AsyncSchemeListComponent,
  AsyncSchemeMasterComponent,
  AsyncLegalCaseComponent,
  AsyncEnggDashboardComponent,
  AsyncCtsoDashboardComponent,
  AsyncDeputyDashboardComponent,
  AsyncCooperationDashboardComponent,
  // AsyncFinanceDashboardComponent,
  AsyncTownPlanDashboardComponent,
  AsyncLegalDepDashboardComponent,
  AsyncLegalHeirComponent,
  AsyncRegister13Component,
  AsyncForm14Part1Component,
  AsyncForm14Part2Component,
  AsyncRegisterAnnexure2Component,
  // AsyncRegNOC144Component,
  AsyncRegisterSection33Component,
  AsyncRegisterOfAgreementComponent,
  // AsyncPaidRehabilitationComponent,
  AsyncEstateForm1Component,
  AsyncEstateForm2Component,
  AsyncApplicationFormComponent,
  AsyncApplicationReportComponent,
  AsyncSlumActComponent,
  AsyncAdvocateComponent,
  AsyncEstateDepDashboardComponent,
  AsyncPaidRehabComponent,
  AsyncPaidRehabDepDashboardComponent,
  AsyncCADepDashboardComponent,
  AsyncApplicationReasonComponent,
  AsyncHolidayComponent,
  AsyncCalendarComponent,
  AsyncCourtTypeComponent,
  AsyncCEODyDashboardComponent,
  AsyncLandAcquisitionComponent,
  AsyncPAPApplication,
  AsyncPAPReport,
  AsyncPdfMasterComponent,
  AsyncPAPLottery,
  AsyncJVSchemeComponent,
  AsyncLandDefermentComponent,
  AsyncLandDefermentDetailsComponent,
} from "routes/ComponentLoader";

const permission = JSON.parse(localStorage.getItem("userData"))
  ? JSON.parse(localStorage.getItem("userData")).authentication
  : {};
const routes = [
  // Default welcome page
  {
    path: "welcome",
    component: AsyncWelcomeComponent,
    meta: {
      permission: true,
    },
  },

  //Acceptance Dashboard's routes & permissions.
  {
    path: "dashboard/acceptance-department",
    component: AsyncAcceptanceDashboardComponent,
    meta: {
      permission:
        permission && permission.Dashboard && permission.Dashboard !== undefined
          ? permission &&
          permission.Dashboard &&
          permission.Dashboard.includes("Acceptance_Dashboard")
          : true,
    },
  },

  //Eng Dashboard's routes & permissions.
  {
    path: "dashboard/engg-department",
    component: AsyncEnggDashboardComponent,
    meta: {
      permission:
        permission && permission.Dashboard && permission.Dashboard !== undefined
          ? permission &&
          permission.Dashboard &&
          permission.Dashboard.includes("Engineer_Dashboard")
          : true,
    },
  },

  //CTSO Dashboard's routes & permissions.
  {
    path: "dashboard/ctso-department",
    component: AsyncCtsoDashboardComponent,
    meta: {
      permission:
        permission && permission.Dashboard && permission.Dashboard !== undefined
          ? permission &&
          permission.Dashboard &&
          permission.Dashboard.includes("CTSO_Dashboard")
          : true,
    },
  },

  //Deputy Dashboard's routes & permissions.
  {
    path: "dashboard/deputy-department",
    component: AsyncDeputyDashboardComponent,
    meta: {
      permission:
        permission && permission.Dashboard && permission.Dashboard !== undefined
          ? permission &&
          permission.Dashboard &&
          permission.Dashboard.includes("Deputy_Collector_Dashboard")
          : true,
    },
  },

  //Cooperation Dashboard's routes & permissions.
  {
    path: "dashboard/co-operative-department",
    component: AsyncCooperationDashboardComponent,
    meta: {
      permission:
        permission && permission.Dashboard && permission.Dashboard !== undefined
          ? permission &&
          permission.Dashboard &&
          permission.Dashboard.includes("Co_Operative_Dashboard")
          : true,
    },
  },

  //Town Planning Dashboard's routes & permissions.
  {
    path: "dashboard/town-planning-department",
    component: AsyncTownPlanDashboardComponent,
    meta: {
      permission:
        permission && permission.Dashboard && permission.Dashboard !== undefined
          ? permission &&
          permission.Dashboard &&
          permission.Dashboard.includes("Town_Planning_Dashboard")
          : true,
    },
  },

  //Legal Department Dashboard route and permissions
  {
    path: "dashboard/legal-department",
    component: AsyncLegalDepDashboardComponent,
    meta: {
      permission:
        permission && permission.Dashboard && permission.Dashboard !== undefined
          ? permission &&
          permission.Dashboard &&
          permission.Dashboard.includes("Legal_Dashboard")
          : true,
    },
  },

  //Estate Department Dashboard route and permissions
  {
    path: "dashboard/estate-department",
    component: AsyncEstateDepDashboardComponent,
    meta: {
      permission:
        permission && permission.Dashboard && permission.Dashboard !== undefined
          ? permission &&
          permission.Dashboard &&
          permission.Dashboard.includes("Estate_Dashboard")
          : true,
    },
  },

  //Paid Rehab Department Dashboard route and permissions
  {
    path: "dashboard/paid-rehab-department",
    component: AsyncPaidRehabDepDashboardComponent,
    meta: {
      permission:
        permission && permission.Dashboard && permission.Dashboard !== undefined
          ? permission &&
          permission.Dashboard &&
          permission.Dashboard.includes("Paid_Rehabilitation_Dashboard")
          : true,
    },
  },

  // CA Dashboard route and permissions
  {
    path: "dashboard/ca-department",
    component: AsyncCADepDashboardComponent,
    meta: {
      permission:
        permission && permission.Dashboard && permission.Dashboard !== undefined
          ? permission &&
          permission.Dashboard &&
          permission.Dashboard.includes("Competent_Authority_Dashboard")
          : true,
    },
  },

  //Master's routes & permissions.
  {
    path: "master/user",
    component: AsyncUserMasterComponent,
    meta: {
      permission:
        permission && permission.Master !== undefined
          ? permission && permission.Master.includes("User_Master")
          : false,
    },
  },
  {
    path: "master/formInformation",
    component: AsyncFormInformationComponent,
    meta: {
      permission:
        permission && permission.Master !== undefined
          ? permission && permission.Master.includes("Form_information_master")
          : false,
    },
  },
  {
    path: "setting/setting",
    component: AsyncSettingComponent,
    meta: {
      permission:
        permission && permission.Setting_Master !== undefined
          ? permission &&
          permission.Setting_Master.includes("Transfer_Of_Tenement_Setting")
          : false,
    },
  },
  // {
  // 	path: 'user-settings',
  // 	component: AsyncUserSettingsComponent,
  // 	meta: {
  // 		permission: permission && permission.SettingsMaster !== undefined
  // 			? permission && permission.SettingsMaster.includes("Setting_master")
  // 			: false
  // 	},
  // },
  {
    path: "master/designation",
    component: AsyncDesignationComponent,
    meta: {
      permission:
        permission && permission.Master !== undefined
          ? permission && permission.Master.includes("Designation_master")
          : false,
    },
  },
  {
    path: "master/department",
    component: AsyncDepartmentComponent,
    meta: {
      permission:
        permission && permission.Master !== undefined
          ? permission && permission.Master.includes("Department_master")
          : false,
    },
  },
  {
    path: "master/area",
    component: AsyncAreaComponent,
    meta: {
      permission:
        permission && permission.Master !== undefined
          ? permission && permission.Master.includes("Area_master")
          : false,
    },
  },
  {
    path: "master/ward",
    component: AsyncWardComponent,
    meta: {
      permission:
        permission && permission.Master !== undefined
          ? permission && permission.Master.includes("Ward_master")
          : false,
    },
  },
  {
    path: "master/scheme-type",
    component: AsyncSchemeTypeComponent,
    meta: {
      permission:
        permission && permission.Master !== undefined
          ? permission && permission.Master.includes("Scheme_Type_master")
          : false,
    },
  },
  {
    path: "master/architect",
    component: AsyncArchitectComponent,
    meta: {
      permission:
        permission && permission.Master !== undefined
          ? permission && permission.Master.includes("Architect_master")
          : false,
    },
  },
  {
    path: "master/developer",
    component: AsyncDeveloperComponent,
    meta: {
      permission:
        permission && permission.Master !== undefined
          ? permission && permission.Master.includes("Developer_master")
          : false,
    },
  },
  {
    path: "master/land-type",
    component: AsyncLandTypeComponent,
    meta: {
      permission:
        permission && permission.Master !== undefined
          ? permission && permission.Master.includes("Land_Type_master")
          : false,
    },
  },
  {
    path: "master/annexure",
    component: AsyncAnnexureComponent,
    meta: {
      permission:
        permission && permission.Master !== undefined
          ? permission && permission.Master.includes("Annexure_master")
          : false,
    },
  },

  {
    path: "create-scheme",
    component: AsyncSchemeMasterComponent,
    meta: {
      permission:
        permission && permission.CreateScheme !== undefined
          ? permission && permission.CreateScheme.includes("Create_Scheme")
          : false,
    },
  },
  {
    path: "schemes",
    component: AsyncSchemeListComponent,
    meta: {
      permission:
        permission && permission.SchemeList !== undefined
          ? permission && permission.SchemeList.includes("Scheme_List")
          : false,
    },
  },
  {
    path: "detail/:id",
    component: Loadable({
      loader: () => import("views/Schemes/SchemeDetails"),
      loading: () => <HulkPageLoader />,
      delay: 3000,
    }),
    meta: {
      navLink: "schemes",
      permission: true,
      // permission && permission.Master !== undefined
      // 	? permission && permission.Master.includes("Unit_Master")
      // 	: false
    },
  },

  {
    path: "land-acquisition",
    component: AsyncLandAcquisitionComponent,
    meta: {
      permission:
        permission && permission.Land_Acquisition !== undefined
          ? permission && permission.Land_Acquisition.length > 0
          : false,
    },
  },
  {
    path: "land-acquisition-details/:id",
    component: Loadable({
      loader: () => import("views/LandAcquisition/LandAcquisitionDetails"),
      loading: () => <HulkPageLoader />,
      delay: 3000,
    }),
    meta: {
      navLink: "land-acquisition-details",
      permission: true,
      // permission && permission.Master !== undefined
      // 	? permission && permission.Master.includes("Unit_Master")
      // 	: false
    },
  },

  {
    path: "viewlog",
    component: Loadable({
      loader: () => import("views/Schemes/viewlog/index.js"),
      loading: () => <HulkPageLoader />,
      delay: 3000,
    }),
    // component: AsyncSchemeDetails,
    meta: {
      navLink: "schemes",
      permission:
        permission && permission.SchemeList !== undefined
          ? permission && permission.SchemeList.includes("Scheme_List")
          : false,
    },
  },
  {
    path: "create-case",
    component: AsyncLegalCaseComponent,
    meta: {
      permission:
        permission && permission.Department !== undefined
          ? permission && permission.Department.includes("Legal_Department")
          : false,
    },
  },
  {
    path: "collector/legal-heir",
    component: AsyncLegalHeirComponent,
    meta: {
      permission:
        permission &&
          permission?.Deputy_Collector_Department &&
          permission?.Deputy_Collector_Department !== undefined
          ? permission &&
          permission?.Deputy_Collector_Department &&
          permission?.Deputy_Collector_Department?.includes(
            "Legal_Heir_varas"
          )
          : false,
    },
  },
  {
    path: "collector/register-case",
    component: AsyncRegister13Component,
    meta: {
      permission:
        permission &&
          permission?.Deputy_Collector_Department &&
          permission?.Deputy_Collector_Department !== undefined
          ? permission &&
          permission?.Deputy_Collector_Department &&
          permission?.Deputy_Collector_Department?.includes(
            "Register_of_section_13_2_case"
          )
          : false,
    },
  },
  {
    path: "collector/form-14-part-1",
    component: AsyncForm14Part1Component,
    meta: {
      permission:
        permission &&
          permission?.Deputy_Collector_Department &&
          permission?.Deputy_Collector_Department !== undefined
          ? permission &&
          permission?.Deputy_Collector_Department &&
          permission?.Deputy_Collector_Department?.includes("Form_14_Part_1")
          : false,
    },
  },
  {
    path: "collector/form-14-part-2",
    component: AsyncForm14Part2Component,
    meta: {
      permission:
        permission &&
          permission?.Deputy_Collector_Department &&
          permission?.Deputy_Collector_Department !== undefined
          ? permission &&
          permission?.Deputy_Collector_Department &&
          permission?.Deputy_Collector_Department?.includes("Form_14_Part_2")
          : false,
    },
  },
  {
    path: "collector/register-annexure-2",
    component: AsyncRegisterAnnexure2Component,
    meta: {
      permission:
        permission &&
          permission?.Deputy_Collector_Department &&
          permission?.Deputy_Collector_Department !== undefined
          ? permission &&
          permission?.Deputy_Collector_Department &&
          permission?.Deputy_Collector_Department?.includes(
            "Register_Of_Annexure_2"
          )
          : false,
    },
  },
  // {

  // 	path: "collector/register-noc-144",
  // 	component: AsyncRegNOC144Component,
  // 	meta: {
  // 		permission: true
  // 		// permission && permission.Department !== undefined
  // 		// 	? permission && permission.Department.includes("Deputy_Collector_Department")
  // 		// 	: false
  // 	},
  // },
  {
    path: "collector/register-section-33-38",
    component: AsyncRegisterSection33Component,
    meta: {
      permission:
        permission &&
          permission?.Deputy_Collector_Department &&
          permission?.Deputy_Collector_Department !== undefined
          ? permission &&
          permission?.Deputy_Collector_Department &&
          permission?.Deputy_Collector_Department?.includes(
            "Register_of_Section_33_Or_38"
          )
          : false,
    },
  },
  {
    path: "collector/register-of-agreement",
    component: AsyncRegisterOfAgreementComponent,
    meta: {
      permission:
        permission &&
          permission?.Deputy_Collector_Department &&
          permission?.Deputy_Collector_Department !== undefined
          ? permission &&
          permission?.Deputy_Collector_Department &&
          permission?.Deputy_Collector_Department?.includes(
            "Register_of_Agreement"
          )
          : false,
    },
  },
  {
    path: "estate-dept/form-1",
    component: AsyncEstateForm1Component,
    meta: {
      permission:
        permission &&
          permission?.Estate_Department &&
          permission?.Estate_Department !== undefined
          ? permission &&
          permission?.Estate_Department &&
          permission?.Estate_Department?.includes("Total_PAPs")
          : false,
    },
  },
  {
    path: "estate-dept/form-2",
    component: AsyncEstateForm2Component,
    meta: {
      permission:
        permission &&
          permission?.Estate_Department &&
          permission?.Estate_Department !== undefined
          ? permission &&
          permission?.Estate_Department &&
          permission?.Estate_Department?.includes("List_of_Application")
          : false,
    },
  },
  {
    path: "tenement-form/application-list",
    component: AsyncApplicationFormComponent,
    meta: {
      permission:
        permission &&
          permission?.TenementForm &&
          permission?.TenementForm !== undefined
          ? permission &&
          permission?.TenementForm &&
          permission?.TenementForm?.includes("Application_List")
          : false,
    },
  },
  {
    path: "tenement-form/application-report",
    component: AsyncApplicationReportComponent,
    meta: {
      permission:
        permission && permission.TenementForm !== undefined
          ? permission && permission.TenementForm.includes("Application_Report")
          : false,
    },
  },

  {
    path: "master/slum-act",
    component: AsyncSlumActComponent,
    meta: {
      permission:
        permission && permission.Master !== undefined
          ? permission && permission.Master.includes("Slum_Act_Master")
          : false,
    },
  },
  {
    path: "master/advocate-court",
    component: AsyncAdvocateComponent,
    meta: {
      permission:
        permission && permission.Master !== undefined
          ? permission && permission.Master.includes("Advocate_Master")
          : false,
    },
  },
  {
    path: "dept/paid-rehabilitation",
    component: AsyncPaidRehabComponent,
    meta: {
      permission:
        permission && permission.Paid_Rehabilitation !== undefined
          ? permission &&
          permission.Paid_Rehabilitation.includes("Paid_Rehabilitation")
          : false,
    },
  },
  {
    path: "master/reason",
    component: AsyncApplicationReasonComponent,
    meta: {
      permission:
        permission && permission.Master !== undefined
          ? permission &&
          permission.Master.includes("Application_Reason_Master")
          : false,
    },
  },
  {
    path: "master/holiday",
    component: AsyncHolidayComponent,
    meta: {
      permission:
        permission && permission.Master !== undefined
          ? permission && permission.Master.includes("Holiday_Master")
          : false,
    },
  },
  {
    path: "calendar",
    component: AsyncCalendarComponent,
    meta: {
      permission:
        permission && permission.Calendar !== undefined
          ? permission && permission.Calendar.includes("Calendar_13_2_Cases")
          : false,
    },
  },
  {
    path: "master/court-type",
    component: AsyncCourtTypeComponent,
    meta: {
      permission:
        permission && permission.Master !== undefined
          ? permission && permission.Master.includes("Court_Type_Master")
          : false,
    },
  },
  // AsyncCEODyDashboardComponent
  {
    path: "dashboard/13-2-cases",
    component: AsyncCEODyDashboardComponent,
    meta: { permission: true },
  },
  {
    path: "pap/pap_application",
    component: AsyncPAPApplication,
    meta: {
      permission:
        permission && permission.PAP_Application !== undefined
          ? permission && permission.PAP_Application.includes("PAP_Application")
          : false,
    },
  },
  {
    path: "pap_detail/:id",
    component: Loadable({
      loader: () => import("views/PAP_Application/papDetails"),
      loading: () => <HulkPageLoader />,
      delay: 3000,
    }),
    meta: {
      permission:
        permission && permission.PAP_Application !== undefined
          ? permission && permission.PAP_Application.includes("PAP_Application")
          : false,
    },
  },
  {
    path: "pap/pap_report",
    component: AsyncPAPReport,
    meta: {
      permission:
        permission && permission.PAP_Application !== undefined
          ? permission && permission.PAP_Application.includes("PAP_Report")
          : false,
    },
  },
  {
    path: "master/pdf",
    component: AsyncPdfMasterComponent,
    meta: {
      permission:
        permission && permission.Master !== undefined
          ? permission && permission.Master.includes("PDF_master")
          : false,
    },
  },
  {
    path: "pap/lottery",
    component: AsyncPAPLottery,
    meta: {
      permission:
        permission && permission.PAP_Application !== undefined
          ? permission && permission.PAP_Application.includes("PAP_Report")
          : false,
    },
  },
  {
    path: "master/jv-scheme",
    component: AsyncJVSchemeComponent,
    meta: {
      permission:
        permission && permission.Master !== undefined
          ? permission && permission.Master.includes("JV_Scheme_Master")
          : false,
    },
  },
  {
    path: "deferment-of-land-premium",
    component: AsyncLandDefermentComponent,
    meta: {
      permission: true
      // permission && permission.Land_deferment !== undefined ? permission && permission.Land_deferment.length > 0 : false,
    },
  },
  {
    path: "deferment-of-land-premium-details/:id",
    component: AsyncLandDefermentDetailsComponent,
    meta: {
      navLink: 'deferment-of-land-premium-details',
      permission: true
    },
  },
];

export default routes;
