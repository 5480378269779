// ** Checks if an object is empty (returns boolean)
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import firebase from '@firebase/app';
import { NotificationManager } from "react-notifications";
import CloseIcon from "@mui/icons-material/Close";
import { downloadReportClick } from 'Redux/actions/landAcquisition';

export const isUserLoggedIn = () => localStorage.getItem('userData')
export const getUserData = () => JSON.parse(localStorage.getItem('userData'))
export const isObjEmpty = obj => Object.keys(obj).length === 0

// ** Returns K format from a number
export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

// ** Converts HTML to string
export const htmlToString = html => html.replace(/<\/?[^>]+(>|$)/g, '')

const MySwal = withReactContent(Swal)
export const handleConfirmText = (props) => {
  return MySwal.fire({
    title: 'login session has been expired ?',
    text: "login again to continue accessing your account !",
    icon: 'warning',
    showCancelButton: false,
    confirmButtonColor: "#3085d6",
    confirmButtonText: 'Yes, logout it!', //how to add css to this button?
    customClass: {
      btn: 'btn btn-danger',
      confirmButton: "btn btn-success",
      cancelButton: 'btn btn-outline-danger ml-1',
    },
    allowOutsideClick: false,
    buttonsStyling: true,

  }).then(function (result) {
    if (result.value) {
      firebase
        .auth()
        .signOut();
      localStorage.removeItem("userData");
      window.localStorage.clear();
      window.localStorage.clear();
      window.location.href = "/"
    }
  })

}
const Axios = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})
Axios.interceptors.request.use(async (config) => {
  await new Promise((resolve) => {
    const unsubscribe = firebase.auth().onAuthStateChanged(() => {
      unsubscribe();
      resolve();
    });
  });
  config.headers.Authorization = `Bearer ${await firebase.auth().currentUser.getIdToken(false)}`;
  return config;
});
Axios.interceptors.response.use(
  response => response,
  error => {
    if (firebase.auth().currentUser === null || error.response.status === 401) {
      handleConfirmText();
    } else {
      return Promise.reject(error);
    }

  }
);
const AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})

export { Axios, AxiosInstance }


export const uploadImageToS3 = (file, setisLoader, foldername, height, width) => {
  const allowedExtensions = ["png", "jpg", "jpeg"];
  const fileExtension = file.name.split(".").pop().toLowerCase();

  if (!allowedExtensions.includes(fileExtension)) {
    NotificationManager.error(
      "Only JPG, PNG, JPEG file types are allowed",
      "Error"
    );
    return Promise.resolve(""); // Return a resolved promise to handle the error
  }

  setisLoader(true);
  return new Promise((resolve, reject) => {
    preSignedUploadImageToS3(file, setisLoader, foldername)
      .then((imageData) => {
        setisLoader(false);
        resolve(imageData);
      })
      .catch((error) => {
        setisLoader(false);
        NotificationManager.error("Failed to upload image", "Error");
        reject(error);
      });
  });
};

export const deleteImageFromS3 = async (e, filepath, setIsLoading) => {
  try {
    const s3UrlPrefix = "https://sra-automation.s3.ap-south-1.amazonaws.com/";
    const filePath = filepath.replace(s3UrlPrefix, "");

    const response = await Axios.post(`/presigned/generate-presigned-url-for-delete`, {
      filePath: filePath
    });

    if (response.data.status === false) {
      return ""
    } else {
      const presignedUrl = response.data.data;
      try {
        const response = await axios.delete(presignedUrl);
        if (response.status === 204) {
          return response
        } else {
          console.error("Failed to delete file", response);
          return ""
        }
      } catch (error) {
        console.error("Error deleting file from S3", error);
        return ""
      }
    }
  } catch (error) {
    console.error("Error uploading file or generating presigned URL:", error);
    return ""
  }
};

//Function for capitalize the text .
export const capitalizeText = (text) => {
  return text
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};



/********************upload video function for form information master**************/
export const UPLOAD_VIDEOS = async (
  file,
  setIsLoading,
  folderName
) => {
  setIsLoading(true);

  if (file.type.includes("video/")) {

    const isVideo = file.type.includes("video/");
    if (isVideo && file.size <= 51000000) {

      return new Promise((resolve, reject) => {
        preSignedUploadImageToS3(file, setIsLoading, folderName)
          .then((imageData) => {
            setIsLoading(false);
            resolve(imageData);
          })
          .catch((error) => {
            setIsLoading(false);
            NotificationManager.error("Failed to upload image", "Error");
            reject(error);
          });
      });

    } else {
      NotificationManager.error(
        "Video size needs to be less than or equal to 50mb",
        "File name: " + file.name
      );
    }
  } else {
    NotificationManager.error("Only video file types are allowed", "Error");
  }
  setIsLoading(false)
}


export const uploadPdfImageToS3 = (file, setIsLoading, foldername, height, width) => {
  const allowedExtensions = ["png", "jpg", "jpeg", "pdf"];
  const fileExtension = file.name.split(".").pop().toLowerCase();

  if (!allowedExtensions.includes(fileExtension)) {
    NotificationManager.error(
      "Only JPG, PNG, JPEG , PDF file types are allowed",
      "Error"
    );
    return Promise.resolve(""); // Return a resolved promise to handle the error
  }

  setIsLoading(true);
  return new Promise((resolve, reject) => {
    preSignedUploadImageToS3(file, setIsLoading, foldername)
      .then((imageData) => {
        setIsLoading(false);
        resolve(imageData);
      })
      .catch((error) => {
        setIsLoading(false);
        NotificationManager.error("Failed to upload image", "Error");
        reject(error);
      });
  });
};

// Get presigned url
export const preSignedUploadImageToS3 = async (file, setIsLoader, folderName) => {
  try {
    // Generate a 4-digit random number
    const randomNumber = Math.floor(1000 + Math.random() * 9000);

    // Get current timestamp
    const timestamp = Date.now();

    const response = await Axios.post(`/presigned/generate-presigned-url`, {
      filePath: `${folderName}/${timestamp}_${randomNumber}_${file.name}`,
      fileType: file.type
    });

    if (response.data.status === false) {
      NotificationManager.error("Failed to upload file!");
      setIsLoader(false)
      return ""
    } else {
      const presignedUrl = response.data.data;
      const uploadResponse = await axios.put(presignedUrl, file, {
        headers: {
          'Content-Type': file.type
        }
      });

      if (uploadResponse.status === 200) {
        const fileUrl = presignedUrl.split('?')[0]; // Get the URL without query parameters
        setIsLoader(false)
        return fileUrl;
      } else {
        NotificationManager.error("Failed to upload file!");
        setIsLoader(false)
        return ""
      }
    }
  } catch (error) {
    NotificationManager.error("Failed to upload file!");
    setIsLoader(false)
    console.error("Error uploading file or generating presigned URL:", error);
    return ""
  }
};

// copy to URL to clipboard
export const copyToClipboard = (text, msg) => {
  if (navigator.clipboard) {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        NotificationManager.success(`${msg} has been copied.`);
      })
      .catch((error) => {
        NotificationManager.error("Failed to copy");
      });
  } else {
    // Fallback for browsers that don't support Clipboard API
    try {
      const input = document.createElement("textarea");
      input.value = text;
      document.body.appendChild(input);
      input.select();
      document.execCommand("copy");
      document.body.removeChild(input);
      NotificationManager.success(`${msg} has been copied.`);
    } catch (error) {
      NotificationManager.error("Failed to copy");
    }
  }
};

// Helper function to get file extension
export const getFileExtension = (fileName) => {
  return fileName.split(".").pop().toLowerCase();
};



export const handleImageChange = async (
  e,
  resizeWidth,
  resizeHeight,
  setImagesNew,
  setShowImagePreviewsNew,
  imagesNew,
  setIsLoading,
  clearErrors
) => {

  const imageMaxSize = 500 * 1024; // 500KB in bytes
  const fileMaxSize = 50 * 1024 * 1024; // 50MB in bytes

  const file = e.target.files[0];

  if (file) {
    const allowedImageTypes = ["image/png", "image/jpeg", "image/jpg"];
    const allowedFileTypes = ["application/pdf"];

    // Validate file type and size
    if (allowedImageTypes.includes(file.type)) {
      if (file.size > imageMaxSize) {
        e.target.value = null;
        NotificationManager.error(
          "Image should be less than 500KB in size",
          "Error"
        );
        return;
      }
    } else if (allowedFileTypes.includes(file.type)) {
      if (file.size > fileMaxSize) {
        e.target.value = null;
        NotificationManager.error(
          "File should be less than 50MB in size",
          "Error"
        );
        return;
      }
    } else {
      e.target.value = null;
      NotificationManager.error(
        "Please select a valid file (PNG, JPG, JPEG, or PDF)",
        "Error"
      );
      return;
    }

    // Proceed with upload if validation passes
    if (imagesNew.length < 5) {
      const folderName = "tenement/letters";
      const uploadedImage = await uploadPdfImageToS3(
        file,
        setIsLoading,
        folderName,
        resizeWidth,
        resizeHeight
      );

      const updatedImages = [...imagesNew, uploadedImage];
      setImagesNew(updatedImages);
      setShowImagePreviewsNew(true);
      clearErrors(`${imagesNew}`);
      e.target.value = null;
    } else {
      e.target.value = null;
      NotificationManager.error(
        "You cannot upload more than 5 media files",
        "Error"
      );
    }
  }
};

export const handleImageRemove = (url, source, setSource, setFilesToDelete) => {
  setSource(source.filter((img) => img !== url));
  setFilesToDelete((prevFilesToDelete) => [...prevFilesToDelete, url]);
  // deleteImageFromS3(null, url, setIsLoading);
};

export const handleUpdateImageRemove = (url, updateimagesNew, setUpdateImagesNew, setFilesToDelete) => {
  setUpdateImagesNew(updateimagesNew.filter((img) => img !== url));
  setFilesToDelete((prevFilesToDelete) => [...prevFilesToDelete, url]);
  // deleteImageFromS3(null, url, setIsLoading);
};

export const renderUpdatePhotos = (updateimages, setUpdateImagesNew, setFilesToDelete) => {
  return updateimages.map((url) => {
    const fileExtension = getFileExtension(url);
    return (
      <div key={url}>
        <CloseIcon
          style={{ alignContent: "flex-end", cursor: "pointer" }}
          onClick={() => handleUpdateImageRemove(url, updateimages, setUpdateImagesNew, setFilesToDelete)}
          className="close_icon_product_video"
        />
        <a key={url} href={url} alt="" rel="noopener noreferrer" target="_blank">
          {fileExtension === "pdf" ? (
            <img
              src={require('assets/Images/pdf.png').default}
              className="img-preview mx-2"
              alt="pdf"
              style={{ maxWidth: "40px", maxHeight: "40px" }}
            />
          ) : (
            <img
              src={url}
              className="img-preview mx-2"
              alt="img"
              style={{
                height: "40px",
                width: "40px",
                boxShadow: "1px 1px 7px 0px #d8cfcf",
                padding: "3px",
              }}
            />
          )}
        </a>
      </div>
    );
  });
};

export const renderPhotos = (source, setSource, setFilesToDelete) => {
  return source.map((url) => {
    const fileExtension = getFileExtension(url);

    return (
      <div key={url}>
        <CloseIcon
          style={{ cursor: "pointer" }}
          close
          className="close_icon_product_video"
          onClick={() => handleImageRemove(url, source, setSource, setFilesToDelete)}
        />
        <a key={url} href={url} alt="" rel="noopener noreferrer" target="_blank">
          {fileExtension === "pdf" ? (
            <img
              src={require('assets/Images/pdf.png').default}
              className="img-preview mx-2"
              alt="pdf"
              style={{ maxWidth: "40px", maxHeight: "40px" }}
            />
          ) : (
            <img
              src={url}
              className="img-preview mx-2"
              alt="img"
              style={{
                height: "40px",
                width: "40px",
                boxShadow: "1px 1px 7px 0px #d8cfcf",
                padding: "3px",
              }}
            />
          )}
        </a>
      </div>
    );
  });
};


//------- view report------------
export const handleViewReportClick = (id, name, setLoading, dispatch) => {
  dispatch(downloadReportClick(id, name, setLoading));
};


// Rent Deferment Table dummy data
export const defermentDummyData = [
  {
    id: 1,
    proposal_no: "PROP001",
    applicant_name: "John Doe",
    mobile_no: "1234567890",
    email: "john.doe@example.com",
    status: "Pending",
    amount: 10000,
    society: "Society Alpha",
    cts_no: "CTS001",
    area: "500 sq.ft.",
    created_at: "2024-12-01",
  },
  {
    id: 2,
    proposal_no: "PROP002",
    applicant_name: "Jane Smith",
    mobile_no: "9876543210",
    email: "jane.smith@example.com",
    status: "Approved",
    amount: 25000,
    society: "Society Beta",
    cts_no: "CTS002",
    area: "750 sq.ft.",
    created_at: "2024-11-25",
  },
  {
    id: 3,
    proposal_no: "PROP003",
    applicant_name: "Michael Brown",
    mobile_no: "4561237890",
    email: "michael.brown@example.com",
    status: "Rejected",
    amount: 15000,
    society: "Society Gamma",
    cts_no: "CTS003",
    area: "650 sq.ft.",
    created_at: "2024-12-05",
  },
  {
    id: 4,
    proposal_no: "PROP004",
    applicant_name: "Emily Davis",
    mobile_no: "7894561230",
    email: "emily.davis@example.com",
    status: "Pending",
    amount: 30000,
    society: "Society Delta",
    cts_no: "CTS004",
    area: "900 sq.ft.",
    created_at: "2024-11-15",
  },
  {
    id: 5,
    proposal_no: "PROP005",
    applicant_name: "Chris Wilson",
    mobile_no: "1237894560",
    email: "chris.wilson@example.com",
    status: "Approved",
    amount: 20000,
    society: "Society Epsilon",
    cts_no: "CTS005",
    area: "400 sq.ft.",
    created_at: "2024-11-30",
  },
];

export const allpaymentsDummyData = [
  {
    id: 1,
    developer_name: "John Developer",
    proposal_no: "PROP1001",
    deferment_amount: "₹50,000",
    date: "2024-11-01",
  },
  {
    id: 2,
    developer_name: "Alice Builder",
    proposal_no: "PROP1002",
    deferment_amount: "₹75,000",
    date: "2024-10-15",
  },
  {
    id: 3,
    developer_name: "Mark Construction",
    proposal_no: "PROP1003",
    deferment_amount: "₹1,00,000",
    date: "2024-09-20",
  },
  {
    id: 4,
    developer_name: "Emily Infrastructure",
    proposal_no: "PROP1004",
    deferment_amount: "₹80,000",
    date: "2024-10-05",
  },
  {
    id: 5,
    developer_name: "Michael Projects",
    proposal_no: "PROP1005",
    deferment_amount: "₹1,25,000",
    date: "2024-11-10",
  },
];

