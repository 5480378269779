/**
 * Functional component to toggle the full screen functionality of the application
 */
import React, { useState } from "react";
import { Tooltip, IconButton, Icon } from "@material-ui/core";
import screenfull from "screenfull";

export default function FullScreen(props) {
  const [isFullScreen, setIsFullScreen] = useState(false);

  //Function to toggle the full screen functionality
  function toggleScreenFull() {
    screenfull.toggle();
    setIsFullScreen(!isFullScreen);
  }

  return (
    <Tooltip
      title={isFullScreen ? "Exit Full Screen" : "Full Screen"}
      placement="bottom"
    >
      <IconButton
        aria-label="settings"
        style={{ padding: "6px" }}
        onClick={() => toggleScreenFull()}
      >
        <Icon className={props.iconColor}>
          {isFullScreen ? "fullscreen_exit" : "fullscreen"}
        </Icon>
      </IconButton>
    </Tooltip>
  );
}
