const initialState = {
    data: [],
    total: 1,
    params: {},
    detailList: {},
    landTypeList: {},
    activityLogList: [],
    hearingList: [],
    activityList: [],
    statusCount: [],
    currentStatus: [],
    trialData: [],
    finalAllotmentData: [],
    lotteryStatus: [],
    flatAllotment: [],
}

const papApplicationReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_DATA_PAP':
            return {
                ...state,
                allData: action.allData,
                data: action.data,
                total: action.totalPages,
                params: action.params
            };
        case "GET_RUN_TRIAL_DATA":
            return {
                ...state,
                trialData: action.data
            }
        case "GET_LOTTERY_STATUS":
            return {
                ...state,
                lotteryStatus: action.data
            }
        case "GET_FINAL_LOTTERY_DATA":
            return {
                ...state,
                finalAllotmentData: action.data
            }
        case 'PAP_UPDATED':
            return {
                ...state,
            }
        case 'UPDATE_HEARING_DATE':
            return {
                ...state,
            }
        case 'GET_HEARING_LIST':
            return {
                ...state,
                hearingList: action.data
            }
        case 'GET_PAP_DATA_LIST':
            return {
                ...state,
                detailList: action.data
            }
        case 'GET_PAP_ACTIVITY':
            return {
                ...state,
                activityList: action.data
            }
        case 'GET_PAP_REPORT':
            return {
                ...state,
                allData: action.allData,
                data: action.data,
                total: action.totalPages,
                params: action.params,
                statusCount: action.statusCounts,
                currentStatus: action?.currentStatus
            }

        case 'GET_WARD_DATA':
            return {
                ...state,
                wardList: action.data
            }
        case 'GET_FLAT_ALLOTMENT':
            return {
                ...state,
                flatAllotment: action.data
            }


        default:
            return { ...state }
    }
}
export default papApplicationReducer
