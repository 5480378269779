const initialState = {
    data: [],
    total: 1,
    params: {},
    detailList: {},
    landTypeList: {},
    activityLogList: []

}

const estateDeptForm2Reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_ESTATE_FORM2_DATA':
            return {
                ...state,
                allData: action.allData,
                data: action.data,
                total: action.totalPages,
                params: action.params
            };
        case 'UPDATE_NEW_ESTATE_FORM2':
            return {
                ...state,
            }

        case 'CREATE_NEW_ESTATE_FORM2':
            return {
                ...state,
            }
        default:
            return { ...state }
    }
}
export default estateDeptForm2Reducer
