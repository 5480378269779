const initialState = {
  data: [],
  total: 1,
  params: {},
  allData: [],
  activeVillageList: [],
}

const SchemeMasterReducer = (state = initialState, action) => {
  switch (action.type) {

    case 'GET_DATA_SCHEME':
      return {
        ...state,
        allData: action.allData,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }

    case 'CREATE_SCHEME':
      return { ...state }

    case 'UPDATE_SCHEME':
      return { ...state }

    case "GET_ACTIVE_SCHEME_TYPE_DATA":
      return {
        ...state,
        activeSchemeTypeData: action.data,
      };

    case "GET_ACTIVE_WARD_DATA":
      return {
        ...state,
        activeWardData: action.data,
      };

    case "GET_ACTIVE_DEVELOPER_DATA":
      return {
        ...state,
        activeDeveloperData: action.data,
      };

    case "GET_ACTIVE_ARCHITECT_DATA":
      return {
        ...state,
        activeArchitectData: action.data,
      };

    case "GET_ACTIVE_LAND_TYPE_DATA":
      return {
        ...state,
        activeLandTypeData: action.data,
      };

    case "GET_ACTIVE_VILLAGE_DATA":
      return {
        ...state,
        activeVillageList: action.data,
      };

    case "GET_ACTIVE_SUB_ENG_DATA":
      return {
        ...state,
        activeSubEngList: action.data,
      };

    case "GET_ACTIVE_ASSIS_ENG_DATA":
      return {
        ...state,
        activeAssistanceEngList: action.data,
      };

    case "GET_ACTIVE_EXECUTE_ENG_DATA":
      return {
        ...state,
        activeExecuteEngList: action.data,
      };
    default:
      return { ...state }
  }
}
export default SchemeMasterReducer
