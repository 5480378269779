const initialState = {
  data: [],
  total: 1,
  params: {},
  allData: [],
  designationData: [],
  branchData: [],
  // change_PasswordData:[],
}

const userReducer = (state = initialState, action) => {
  switch (action.type) {

    case 'GET_DATA_USER':
      return {
        ...state,
        allData: action.allData,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'CREATE_USER':
      return { ...state }
    case 'UPDATE_USER':
      return { ...state }
    case "GET_BRANCH_DATA":
      return {
        ...state,
        branchData: action.data,
      };

    case 'GET_USER_ACCESS':
      return {
        ...state,
        userAccess: action.data
      };

    case "GET_DESIGNATION_DATA":
      return {
        ...state,
        designationData: action.data,
      };

    case "GET_DEPARTMENT_DATA":
      return {
        ...state,
        departmentData: action.data,
      };

    case "GET_WARD_DATA":
      return {
        ...state,
        wardData: action.data,
      };

    default:
      return { ...state }
  }
}
export default userReducer
