const initialState = {
    data: [],
    total: 1,
    params: {},
    allData: [],

}

const registerAnnexure2Reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_REG_ANNE2_DATA':
            return {
                ...state,
                allData: action.allData,
                data: action.data,
                total: action.totalPages,
                params: action.params
            };

        case "CREATE_ANNEXURE2":
            return {
                ...state,
            };
        case "UPDATE_ANNEXURE2":
            return {
                ...state,
            };


        default:
            return { ...state }
    }
}
export default registerAnnexure2Reducer
